<template>
  <div>
    <checkInCheckOut :data="entry" :callback="setSuccess" />
  </div>
</template>
<script>
export default {
  name: 'checkIn',
  components: {
    checkInCheckOut() {
      return import('@/components/checkIn/page/edit/index.vue')
    }
  },
  props: {
    entry: {
      type: Object
    },
    callback: {
      type: Function,
      default () { return () => {} }
    }
  },
  data() {
    return {
    }
  },
  computed: {},
  mounted() {},
  methods: {
    setSuccess (data) {
      this.callback(data)
    }
  }
}
</script>
<style lang="sass" scoped></style>
